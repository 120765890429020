<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/img/logoValo.png">
    <h1>Bienvenue sur Valo Guigui !!</h1>
  </div>
</template>

<script>
export default {
  name: 'AccueilView',
}
</script>
