<template>
  <div class="about">
    <div class="general">
      <div class="unique" v-for="spray in info" :key="spray.uuid">
        <img :src="spray.displayIcon" alt="Image de l'arme">
        <h2>{{ spray.displayName }}</h2>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  data() {
    return {
      info: []
    }
  },
  mounted() {
    this.axios
      .get('https://valorant-api.com/v1/agents')
      .then(response => (this.info = response.data.data))
  },
}
</script>