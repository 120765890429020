<template>
  <nav class="header">
    <router-link to="/">Accueil</router-link>
    <router-link to="/agents">Agents</router-link>
    <router-link to="/armes">Armes</router-link>
    <router-link to="/maps">Cartes</router-link>
    <router-link to="/cards">Cartes d'agents</router-link>
    <router-link to="/sprays">Sprays</router-link>
    <router-link to="/buddies">Portes-clés</router-link>
    <router-link to="/bundles">Skins</router-link>
    <router-link to="/levelborders">Bordures de niveaux</router-link>
  </nav>
  <div class="view">
    <router-view />
  </div>
</template>

<style>
@import url(assets/css/reset.css);
@import url(assets/css/style.css);
</style>
