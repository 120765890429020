import { createRouter, createWebHistory } from 'vue-router'
import AccueilView from '../views/AccueilView.vue'
import ArmesView from '../views/ArmesView.vue'
import CardsView from '../views/CardsView.vue'
import SpraysView from '../views/SpraysView.vue'
import AgentsView from '../views/AgentsView.vue'
import BuddiesView from '../views/BuddiesView.vue'
import BundlesView from '../views/BundlesView.vue'
import LevelBordersView from '../views/LevelBordersView.vue'
import MapsView from '../views/MapsView.vue'

const routes = [
  {
    path: '/',
    name: 'accueil',
    component: AccueilView
  },
  {
    path: '/armes',
    name: 'armes',
    component: ArmesView
  },
  {
    path: '/cards',
    name: 'cards',
    component: CardsView
  },
  {
    path: '/sprays',
    name: 'sprays',
    component: SpraysView
  },
  {
    path: '/agents',
    name: 'agents',
    component: AgentsView
  },
  {
    path: '/buddies',
    name: 'buddies',
    component: BuddiesView
  },
  {
    path: '/bundles',
    name: 'bundles',
    component: BundlesView
  },
  {
    path: '/levelBorders',
    name: 'levelBorders',
    component: LevelBordersView
  },
  {
    path: '/maps',
    name: 'maps',
    component: MapsView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
